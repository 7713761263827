















































































































































































import { Component, Vue } from 'vue-property-decorator'
import OrderAddress from '@/components/OrderAddress.vue'
import OrderInvoice from '@/components/OrderInvoice.vue'
import {
  checkInvalidMaterialList,
  checkInvalidAndOrder,
  commitOrder
} from "@/api";
interface IOrderInfo {
  addressId?: number
  invoiceId?: number
  orderMsg?: string
  paymentMethod?: string
  provinceId?:string
  moneyType?:string
}

interface IProduct {
  id: string
  createTime: string
  partnumber: string
  mft: string
  description: string
  amount: number
  time: string
  rsp: Array<string>
  stockstatus: Array<string>
  unitPrice: number
  totalPrice: number
  materialId : string
  isValid: number
  outMsg: string
  mftId:string
  pc:string
  goodsUuid:string
}
// interface ICommitOrderDetail {
//   materialId :number
//   outMsg :string
//   amount: number

// }
// interface IOrderCommit {
//   customerGoodsId :number
//   userInvoiceId :string
//   orderMsg :string
//   payType: number
//   commitOrderDetailVoList : Array<ICommitOrderDetail> 
// }
@Component({
  components: {
    OrderAddress,
    OrderInvoice,
  },
})
export default class ConfirmOrder extends Vue {
  orderInfo: IOrderInfo = {
    paymentMethod: '3',
    provinceId:"0",
    moneyType:"2"
  }
  products: Array<IProduct> = []
  isAgreed = false
  checkMaterialIds : Array<number> =[];
  loading = true;
  freightFee = 0;
  orderPrice = 0;
  goodsPrice = 0;
  rmbFreightFee = 0;
  rmbOrderPrice = 0;
  rmbGoodsPrice = 0;
  goodsCount = 0;
  showAgreement = false;
  from = "0";
  payTipsVisible = true;
  invoiceVisible = false;
  // commitOrderData : IOrderCommit ;
  
  mounted(): void {
    let materialId  = this.$route.query.checkedProducts;
    // console.log(materialId)
    let from  = this.$route.query.from.toString();
    if(from == "cart"){
      this.checkInvalidMaterialList(materialId );
      this.from = "0";
    }else if(from == "detail"){
      let amount  = this.$route.query.amount.toString();
      this.checkInvalidAndOrder(materialId ,amount);
      this.from = "1";
    }
    
  }

  //检查是否有失效物料--购物车入口
  checkInvalidMaterialList(data : string | string []): void {

    if(typeof(data) != "string"){
      return ; 
    }
    checkInvalidMaterialList(data.split(","))
      .then((res) => {
        this.loading = false;
        if (res.status == 200) {
          if (res.data.code == 200) {
            // console.log(res.data.data)
            
            if(res.data.data.existInvalidGoods){
              this.$confirm('存在已失效商品,请返回购物车确认!', '', {
                confirmButtonText: '去首页逛逛',
                cancelButtonText: '前往购物车',
                type: 'warning'
              }).then(() => {
                this.$router.replace("/");
              }).catch(() => {
                this.$router.replace({ name: 'Cart' });
              });
              
            }else{
              this.products = res.data.data.pwShoppingCarVoList;
              //usd
              this.freightFee = res.data.data.freightFee;
              this.orderPrice = res.data.data.orderPrice;
              this.goodsPrice = res.data.data.goodsPrice;
              //rmb
              this.rmbFreightFee = res.data.data.rmbFreightFee;
              this.rmbOrderPrice = res.data.data.rmbOrderPrice;
              this.rmbGoodsPrice = res.data.data.rmbGoodsPrice;

              this.goodsCount = res.data.data.goodsCount;
            }
            // this.productIds = this.products.map((item) => item.id)
            
            // this.userInfo = res.data.user
            // window.localStorage.setItem("userInfo",JSON.stringify(res.data.user));
          } else {
            this.$message.error(res.data.msg);
          }
        } else {
          this.$message.error(res.data.statusText);
        }
      })
      .catch(() => {
        console.log(" error ");
      });
  }

 //检查是否有失效物料--产品详情入口
  checkInvalidAndOrder(data : string | string [],amount :string): void {

    if(typeof(data) != "string"){
      return ; 
    }
    console.log(data,amount)
    checkInvalidAndOrder(data.split(","),amount)
      .then((res) => {
        this.loading = false;
        if (res.status == 200) {
          if (res.data.code == 200) {
            // console.log(res.data.data)
            
            if(res.data.data.existInvalidGoods){
              this.$confirm('存在已失效商品,请重新确认!', '', {
                confirmButtonText: '去首页逛逛',
                cancelButtonText: '前往购物车',
                type: 'warning'
              }).then(() => {
                this.$router.replace("/");
              }).catch(() => {
                this.$router.replace({ name: 'Cart' });
              });
              
            }else{
              this.products = res.data.data.pwShoppingCarVoList;
              this.freightFee = res.data.data.freightFee;
              this.orderPrice = res.data.data.orderPrice;
              this.goodsPrice = res.data.data.goodsPrice;
              this.goodsCount = res.data.data.goodsCount;
              //rmb
              this.rmbFreightFee = res.data.data.rmbFreightFee;
              this.rmbOrderPrice = res.data.data.rmbOrderPrice;
              this.rmbGoodsPrice = res.data.data.rmbGoodsPrice;
            }
            // this.productIds = this.products.map((item) => item.id)
            
            // this.userInfo = res.data.user
            // window.localStorage.setItem("userInfo",JSON.stringify(res.data.user));
          } else {
            this.$message.error(res.data.msg);
          }
        } else {
          this.$message.error(res.data.statusText);
        }
      })
      .catch(() => {
        console.log(" error ");
      });
  }
  getInitCurrentAddress (param: {id:number,provinceId:string}):void{
    // this.orderInfo.addressId = addressId
     console.log(JSON.stringify(param))
    this.orderInfo.addressId = param.id
    this.orderInfo.provinceId = param.provinceId
     console.log(JSON.stringify(this.orderInfo))
  }
  //提交订单
  commitOrder( ): void {
    if(!this.isAgreed){
      this.$message.warning("请先阅读并同意协议");
      return ;
    }
    if(this.orderInfo.addressId == 0){
      this.$message.warning("请先选择一个地址");
      return ;
    }
    let data = {
      commitOrderDetailVoList: this.products,
      customerGoodsId :this.orderInfo.addressId,
      userInvoiceId :this.orderInfo.invoiceId,
      orderMsg :this.orderInfo.orderMsg,
      payType: this.orderInfo.paymentMethod,
      from : this.from,
      moneyType:this.orderInfo.moneyType
    };
    // console.log(data);
    this.loading = true
    commitOrder(data)
      .then((res) => {
        // console.log(res)
      
        if (res.status == 200) {
          if (res.data.code == 200) {
              
            if(this.orderInfo.paymentMethod == "0"){
              this.loading = true;

              let price :string = res.data.data.price;
              let aliPayInfo :string = res.data.data.aliPayInfo;
              let orderNo :string = res.data.data.orderNo;
              const div = document.createElement("div"); 
              div.innerHTML = aliPayInfo; 
              div.setAttribute("style", "display:none;");
              document.body.appendChild(div);
              document.forms[1].submit();
              // this.$router.push({ path: '/aliPay', query: { orderNo : `${orderNo}` ,price : `${price}`,aliPayInfo : `${aliPayInfo}`}});
            }else if(this.orderInfo.paymentMethod == "1"){
              this.loading = false
              let price :string = res.data.data.price;
              let codeUrl :string = res.data.data.codeUrl;
              let orderNo :string = res.data.data.orderNo;
           
              this.$router.push({ path: '/wxPay', query: { orderNo : `${orderNo}` ,price : `${price}`,codeUrl : `${codeUrl}`}});
            }else if(this.orderInfo.paymentMethod == "3"){
              this.loading = false
              this.$router.replace("order");
            }
        
            // this.products = res.data.data;
            // this.productIds = this.products.map((item) => item.id)
            // this.calculatePrice()
            // console.log(this.products)
            // console.log(this.productIds)
            // this.userInfo = res.data.user
            // window.localStorage.setItem("userInfo",JSON.stringify(res.data.user));
            // this.$message.success("添加成功");
            // this.dialogFormVisible = false;
            // this.listUserAddress();
            
          } else {
            this.loading = false
            this.$message.warning(res.data.msg);
          }
        } else {
          this.$message.error(res.data.statusText);
        }
      })
      .catch(() => {
        this.$message.error("服务器出了点小问题,工程师正在玩命抢修中");
      });
  }
  // getProducts(): void {
  //   for (let i = 1; i < 4; i++) {
  //     this.products.push({
  //       id: i,
  //       productName: 'Channel Single ADC Pipelined 2Gsps 12-bit JESD204B 1',
  //       productDetail: 'AD9625BBPZ-2.0',
  //       unitprice: '¥3.5021',
  //       duration: '5-9工作日',
  //       amount: 120,
  //       total: '1W',
  //       orderMsg: '',
  //     })
  //   }
  // }
  setOrderAddress(param: {id:number,provinceId:string}):void {
    console.log(JSON.stringify(param))
    this.orderInfo.addressId = param.id
    this.orderInfo.provinceId = param.provinceId
     console.log(JSON.stringify(this.orderInfo))
  }

  setInvoice(id: number):void {
    this.orderInfo.invoiceId = id
  }

  selectPayMethod(method: string):void {
    if(method == '3'){
      this.payTipsVisible = true;
    }else{
      this.payTipsVisible = false;
    }
    
    this.orderInfo.paymentMethod = method
  }

 selectMoneyType(moneyType: string):void {
    if(moneyType == '1'){
      this.invoiceVisible = true;
      this.orderInfo.paymentMethod = '0'
      this.payTipsVisible = false;

    }else{
      this.orderInfo.paymentMethod = '3'
      this.payTipsVisible = true;

      this.invoiceVisible = false;
    }
    
    this.orderInfo.moneyType = moneyType
  }

  submitOrder():void {
    // console.log(this.orderInfo)
    // console.log(this.products)
    this.commitOrder();
    
  }
}
