










































































import { Form } from 'element-ui'
import { Component, Vue } from 'vue-property-decorator'
import chinaDataJson from "@/assets/datajson/china.json";
import {
  listUserAddress,
  insertUserAddress,
  updateUserAddress,
  delUserAddress,
  getUserInfo,
} from "@/api";

interface IAddress {
  id?: number
  linkMan?: string
  mobile?: string
  phone?: string
  qq?: string
  email?: string
  isMain?: number
  address?: string
  isChecked?: boolean
  cascaderVal?: Array<number>
}

@Component
export default class OrderAddress extends Vue {
  showAddressDialog = false
  addressList: Array<IAddress> = []
  currentAddress: IAddress = {}
  userInfo = {
    isVip : 0,
    companyId : "0",
    userId : 0,
  };
 chinaData = chinaDataJson;
  rules = {
    linkMan: [{ required: true, message: '请输入收货人姓名', trigger: 'blur' }],
    mobile: [
      { required: true, message: '请输入手机号', trigger: 'blur' },
      { validator: this.validateMobile, trigger: 'blur' },
    ],
    address: [{ required: true, message: '请输入收货地址', trigger: 'blur' }],
    cascaderVal: [{ type:'array', required: true, message: '请选择省市区', trigger: 'change' }],
    email: [
      { required: true, message: '请输入邮箱地址', trigger: 'blur' },
      {
        type: 'email',
        message: '请输入正确的邮箱地址',
        trigger: 'blur',
      },
    ],
  }

  mounted(): void {
    this.getUserInfo()
  }
  // eslint-disable-next-line
  validateMobile(rule: any, value: string, callback: any): void {
    if (!/^[1][3,4,5,7,8][0-9]{9}$/.test(value)) {
      callback(new Error('手机号格式不正确'))
    } else {
      callback()
    }
  }
  getUserInfo(): void{
  getUserInfo({}).then(res => {
    if(res.status == 200){
        if(res.data.code == 200){
          this.userInfo = res.data.user
          window.localStorage.setItem("userInfo",JSON.stringify(res.data.user));
          this.listUserAddress();
        }else{
          this.$message.error(res.data.msg );
        }
      }else{
        this.$message.error(res.data.statusText);
      } 
    }).catch(() => {
      console.log(' error ')
    })
};

 //地址列表
  listUserAddress(): void {
  
    listUserAddress()
      .then((res) => {
        if (res.status == 200) {
          if (res.data.code == 200) {
            
            this.addressList = res.data.data;
            let currentAddressId = 0;
            let currentProvinceId = 0 ;
            this.addressList = this.addressList.map((item) => {
              if (item.isMain == 1 && item.cascaderVal) {
                item.isChecked = true
                currentAddressId = item.id
                currentProvinceId = item.cascaderVal[0]
              }
              return item
            })
            this.$emit('init', {id:currentAddressId,provinceId:currentProvinceId})
            // this.userInfo = res.data.user
            // window.localStorage.setItem("userInfo",JSON.stringify(res.data.user));
          } else {
            this.$message.error(res.data.msg);
          }
        } else {
          this.$message.error(res.data.statusText);
        }
      })
      .catch(() => {
        console.log(" error ");
      });
  }



  selectAddress(addr: IAddress): void {
    this.addressList = this.addressList.map((item) => {
      item.isChecked = false
      if (item.id === addr.id) {
        if(addr.cascaderVal == null || addr.cascaderVal.length == 0){
          this.$message.error("选中的地址没有选择省市区,请先完善");
          return item;
        }
        item.isChecked = true
      }
      return item
    })
    console.log(addr.cascaderVal)
    if(addr.cascaderVal == null || addr.cascaderVal.length == 0){
      this.$emit('chooseAddress', {id:0,provinceId:"0"})
    }else{
      this.$emit('chooseAddress',  {id:addr.id,provinceId:addr.cascaderVal[0]})
    }
    
  }

  setDefault(addr: IAddress): void {
    this.addressList = this.addressList.map((item) => {
      item.isMain = 0
      if (item.id === addr.id) {
        item.isMain = 1
      }
      return item
    })
  }

  edit(addr: IAddress): void {
    // console.log(addr)
    this.currentAddress = addr
    this.currentAddress.cascaderVal = addr.cascaderVal
    console.log(this.currentAddress.cascaderVal)
    this.showAddressDialog = true
  }

  del(addr: IAddress): void {
    this.addressList = this.addressList.filter((item) => {
      return item.id !== addr.id
    })
  }

  validateForm(): void {
    ;(this.$refs['addressForm'] as Form).validate((valid: boolean) => {
      if (valid) {
        // this.submitAddress()
        this.insertOrUpdate()
      } else {
        console.log('error submit!!')
        return false
      }
    })
  }
  insertOrUpdate():void {
    if(this.currentAddress.id){
      this.updateUserAddress();
    }else{
      this.insertUserAddress();
    }
  }
    //新增
  insertUserAddress(): void {
    // this.currentAddress.id = "";
    let userInfo = JSON.parse(window.localStorage.getItem("userInfo"));
    let data = {
      mobile: this.currentAddress.mobile,
      address: this.currentAddress.address,
      isMain: this.currentAddress.isMain?"1":"0",
      userId: userInfo.userId,
      customerId : this.userInfo.companyId,
      linkMan : this.currentAddress.linkMan,  
      phone : this.currentAddress.phone,
      qq:this.currentAddress.qq,
      email:this.currentAddress.email,
      cascaderVal:this.currentAddress.cascaderVal.toString(),
    };
    insertUserAddress(data)
      .then((res) => {
        // console.log(res)
        if (res.status == 200) {
          if (res.data.code == 200) {
            // console.log(res)
            // this.userInfo = res.data.user
            // window.localStorage.setItem("userInfo",JSON.stringify(res.data.user));
            this.$message.success("添加成功");
            this.showAddressDialog = false;
            this.listUserAddress();
          } else {
            this.$message.warning(res.data.msg);
          }
        } else {
          this.$message.error(res.data.statusText);
        }
      })
      .catch(() => {
        this.$message.error("服务器出了点小问题,工程师正在玩命抢修中");
      });
  }
  //修改
  updateUserAddress(): void {

    let userInfo = JSON.parse(window.localStorage.getItem("userInfo"));
    let data = {
      mobile: this.currentAddress.mobile,
      linkMan: this.currentAddress.linkMan,
      address: this.currentAddress.address,
      isMain: this.currentAddress.isMain?"1":"0",
      userId: userInfo.userId,
      id: this.currentAddress.id,
      customerId : this.userInfo.companyId,
      phone : this.currentAddress.phone,
      qq:this.currentAddress.qq,
      email:this.currentAddress.email,
      cascaderVal:this.currentAddress.cascaderVal.toString(),
    };
    
    updateUserAddress(data)
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          if (res.data.code == 200) {
            console.log(res);
            this.$message.success("修改成功");
            this.showAddressDialog = false;
            this.listUserAddress();
            // this.userInfo = res.data.user
            // window.localStorage.setItem("userInfo",JSON.stringify(res.data.user));
          } else {
            this.$message.error(res.data.msg);
          }
        } else {
          this.$message.error(res.data.statusText);
        }
      })
      .catch(() => {
        console.log(" error ");
      });
  }
delUserAddress(row: any):void{
  delUserAddress(row.id)
            .then((res) => {
              
              if (res.status == 200) {
                if (res.data.code == 200) {
                  this.$message.success("删除成功");
                  this.listUserAddress();
                  // this.userInfo = res.data.user
                  // window.localStorage.setItem("userInfo",JSON.stringify(res.data.user));
                } else {
                  this.$message.error(res.data.msg);
                }
              } else {
                this.$message.error(res.data.statusText);
              }
            })
            .catch(() => {
              console.log(" error ");
            });
      
}
  submitAddress(): void {
    if (this.currentAddress.id) {
      this.addressList = this.addressList.map((item) => {
        if (item.id === this.currentAddress.id) {
          item = this.currentAddress
        }
        return item
      })
    } else {
      this.currentAddress.id = this.addressList.length + 1
      this.addressList.unshift(this.currentAddress)
    }

    this.showAddressDialog = false
  }
   handleCascaderChange(value: Array<number>) : void{
        console.log(value);
  }
}
