














































































































































import { Form } from 'element-ui'
import { Component, Vue } from 'vue-property-decorator'
import {
  listUserInvoice,
  insertUserInvoice,
  updateUserInvoice,
  delUserInvoice,
  getUserInfo,
} from "@/api";

interface IInvoice {
  id?: string
  userId?: number
  unitName?: string
  taxCode?: string
  regAddress?: string
  regPhone?: string
  bank?: string
  account?: string
  receiverName?: string
  receiverPhone?: string
  receiverAddress?: string
  receiverMail?: string
  type?: number

}

@Component
export default class OrderInvoice extends Vue {
  showInvoiceDialog = false
  showNormalDialog = false
  invoiceList: Array<IInvoice> = []
  currentInvoice: IInvoice = {type:0}
  selectedInvoiceID = 0
  userInfo = {
      isVip : 0,
      companyId : "0",
      userId : 0,
  };
  
  rules = {
    unitName: [{ required: true, message: '请输入开票公司名称', trigger: 'blur' }],
    regAddress: [{ required: true, message: '请输入公司注册地址', trigger: 'blur' }],
    regPhone: [{ required: true, message: '请输入公司电话', trigger: 'blur' }],
    taxCode: [{ required: true, message: '请输入税务登记号', trigger: 'blur' }],
    bank: [{ required: true, message: '请输入开户银行', trigger: 'blur' }],
    account: [{ required: true, message: '请输入开户银行账号', trigger: 'blur' }],
    receiverName: [{ required: true, message: '请输入收票人', trigger: 'blur' }],
    receiverPhone: [{ required: true, message: '请输入收票人手机号', trigger: 'blur' }, { validator: this.validateMobile, trigger: 'blur' },],
    receiverAddress: [{ required: true, message: '请输入寄送详细地址', trigger: 'blur' }],
    receiverMail: [
     {
        type: 'email',
        message: '请输入正确的邮箱地址',
        trigger: 'blur',
      },
    ],
    type: [{ required: true, message: '请选择公司性质', trigger: 'change' }],
  }

  normalRules = {
    // unitName: [{ required: true, message: '请输入开票公司名称', trigger: 'blur' }],
    // regAddress: [{ required: true, message: '请输入公司注册地址', trigger: 'blur' }],
    // regPhone: [{ required: true, message: '请输入公司电话', trigger: 'blur' }],
    // taxCode: [{ required: true, message: '请输入税务登记号', trigger: 'blur' }],
    // bank: [{ required: true, message: '请输入开户银行', trigger: 'blur' }],
    // account: [{ required: true, message: '请输入开户银行账号', trigger: 'blur' }],
    receiverName: [{ required: true, message: '请输入收票人', trigger: 'blur' }],
    receiverPhone: [{ required: true, message: '请输入收票人手机号', trigger: 'blur' }, { validator: this.validateMobile, trigger: 'blur' },],
    receiverAddress: [{ required: true, message: '请输入寄送详细地址', trigger: 'blur' }],
    receiverMail: [
     {
        type: 'email',
        message: '请输入正确的邮箱地址',
        trigger: 'blur',
      },
    ],
    type: [{ required: true, message: '请选择公司性质', trigger: 'change' }],
  }
  mounted(): void {
    // this.getInvoice()
    this.getUserInfo()
  }

  // getInvoice(): void {
  //   // TODO: fetch user invoice list from api
  //   for (let i = 1; i < 6; i++) {
  //     this.invoiceList.push({
  //       id: i,
  //       companyName: 'MCON' + i,
  //       companyAddr: '18201379093',
  //       phone: '123123213',
  //       invoiceNumber: '4123123',
  //       bankName: '12312312312123',
  //       bankno: '12312312312123',
  //       name: '12312312312123',
  //       mobile: '18123123123',
  //       address: '1-Channel Single ADC Pipelined 2Gsps 12-bit JESD204B 196-Pin TEBGA Tray',
  //       isMain: false,
  //     })
  //   }

  //   this.invoiceList[1].isMain = true
  // }

  selectInvoice(id: IInvoice): void {
    this.$emit('chooseInvoice', id)
  }
getUserInfo(): void{
  getUserInfo({}).then(res => {
    if(res.status == 200){
        if(res.data.code == 200){
          this.userInfo = res.data.user
          window.localStorage.setItem("userInfo",JSON.stringify(res.data.user));
          this.listUserInvoice();
        }else{
          this.$message.error(res.data.msg );
        }
      }else{
        this.$message.error(res.data.statusText);
      } 
    }).catch(() => {
      console.log(' error ')
    })
};
 insertOrUpdate():void {
    if(this.currentInvoice.id){
      this.updateUserInvoice();
    }else{
      this.insertUserInvoice();
    }
  }
 //发票列表
  listUserInvoice(): void {
  
    listUserInvoice("all")
      .then((res) => {
        if (res.status == 200) {
          if (res.data.code == 200) {
            // console.log(res.data.data)
            this.invoiceList = res.data.data;
            // this.userInfo = res.data.user
            // window.localStorage.setItem("userInfo",JSON.stringify(res.data.user));
          } else {
            this.$message.error(res.data.msg);
          }
        } else {
          this.$message.error(res.data.statusText);
        }
      })
      .catch(() => {
        console.log(" error ");
      });
  }
  //新增
  insertUserInvoice(): void {
    if(this.currentInvoice.type != 0 && (this.currentInvoice.taxCode == null || this.currentInvoice.taxCode == '')){
        this.$message.warning("税号不能为空");
    }
    this.currentInvoice.id = "";
    let userInfo = JSON.parse(window.localStorage.getItem("userInfo"));
    let data = {
      userId: userInfo.userId,
      unitName: this.currentInvoice.unitName,
      taxCode: this.currentInvoice.taxCode,
      regAddress: this.currentInvoice.regAddress,
      regPhone: this.currentInvoice.regPhone,
      bank: this.currentInvoice.bank,
      account : this.currentInvoice.account,
      receiverName : this.currentInvoice.receiverName,  
      receiverPhone : this.currentInvoice.receiverPhone,
      receiverAddress:this.currentInvoice.receiverAddress,
      type:this.currentInvoice.type,
      receiverMail:this.currentInvoice.receiverMail
    };
    insertUserInvoice(data)
      .then((res) => {
        // console.log(res)
        if (res.status == 200) {
          if (res.data.code == 200) {
            // console.log(res)
            // this.userInfo = res.data.user
            // window.localStorage.setItem("userInfo",JSON.stringify(res.data.user));
            this.$message.success("添加成功");
             if(this.currentInvoice.type == 2){
              this.showInvoiceDialog = false
            }else{
              this.showNormalDialog = false
            }
            this.listUserInvoice();
          } else {
            this.$message.warning(res.data.msg);
          }
        } else {
          this.$message.error(res.data.statusText);
        }
      })
      .catch(() => {
        this.$message.error("服务器出了点小问题,工程师正在玩命抢修中");
      });
  }
  //修改
  updateUserInvoice(): void {
    
    if(this.currentInvoice.type != 0 && (this.currentInvoice.taxCode == null || this.currentInvoice.taxCode == '')){
        this.$message.warning("税号不能为空");
        return ;
    }
    let userInfo = JSON.parse(window.localStorage.getItem("userInfo"));
    let data = {
      id: this.currentInvoice.id,
      userId: userInfo.userId,
      unitName: this.currentInvoice.unitName,
      taxCode: this.currentInvoice.taxCode,
      regAddress: this.currentInvoice.regAddress,
      regPhone: this.currentInvoice.regPhone,
      bank: this.currentInvoice.bank,
      account : this.currentInvoice.account,
      receiverName : this.currentInvoice.receiverName,  
      receiverPhone : this.currentInvoice.receiverPhone,
      receiverAddress:this.currentInvoice.receiverAddress,
      type:this.currentInvoice.type,
      receiverMail:this.currentInvoice.receiverMail
    };
    
    updateUserInvoice(data)
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          if (res.data.code == 200) {
            console.log(res);
            this.$message.success("修改成功");
            if(this.currentInvoice.type == 2){
              this.showInvoiceDialog = false
            }else{
              this.showNormalDialog = false
            }
            this.listUserInvoice();
            // this.userInfo = res.data.user
            // window.localStorage.setItem("userInfo",JSON.stringify(res.data.user));
          } else {
            this.$message.error(res.data.msg);
          }
        } else {
          this.$message.error(res.data.statusText);
        }
      })
      .catch(() => {
        console.log(" error ");
      });
  }
  delUserInvoice(row :any):void{
     ///////删除请求
          delUserInvoice(row.id)
            .then((res) => {
              
              if (res.status == 200) {
                if (res.data.code == 200) {
                  this.$message.success("删除成功");
                  this.listUserInvoice();
                  // this.userInfo = res.data.user
                  // window.localStorage.setItem("userInfo",JSON.stringify(res.data.user));
                } else {
                  this.$message.error(res.data.msg);
                }
              } else {
                this.$message.error(res.data.statusText);
              }
            })
            .catch(() => {
              console.log(" error ");
            });
     
      ///////删除请求
      
  }
  // setDefault(invoice: IInvoice): void {
  //   this.invoiceList = this.invoiceList.map((item) => {
  //     item.isMain = false
  //     if (item.id === invoice.id) {
  //       item.isMain = true
  //     }
  //     return item
  //   })
  // }

  edit(invoice: IInvoice): void {
    console.log(invoice)
    this.currentInvoice = invoice
    if(invoice.type == 2){
      this.showInvoiceDialog = true
    }else{
      this.showNormalDialog = true
    }
  }

  del(invoice: IInvoice): void {
    this.invoiceList = this.invoiceList.filter((item) => {
      return item.id !== invoice.id
    })
  }
  validateMobile(rule: any, value: string, callback: any): void {
    if (!/^[1][3,4,5,7,8][0-9]{9}$/.test(value)) {
      callback(new Error('手机号格式不正确'))
    } else {
      callback()
    }
  }
  validateForm(): void {
    ;(this.$refs['invoiceForm'] as Form).validate((valid: boolean) => {
      if (valid) {
        // this.submitInvoice()
        this.insertOrUpdate();
      } else {
        console.log('error submit!!')
        return false
      }
    })
  }
  validateNormalForm(): void {
    ;(this.$refs['normalInvoiceForm'] as Form).validate((valid: boolean) => {
      if (valid) {
        // this.submitInvoice()
        this.insertOrUpdate();
      } else {
        console.log('error submit!!')
        return false
      }
    })
  }
  // submitInvoice(): void {
  //   if (this.currentInvoice.id) {
  //     this.invoiceList = this.invoiceList.map((item) => {
  //       if (item.id === this.currentInvoice.id) {
  //         item = this.currentInvoice
  //       }
  //       return item
  //     })
  //   } else {
  //     this.currentInvoice.id = this.invoiceList.length + 1
  //     this.invoiceList.unshift(this.currentInvoice)
  //   }

  //   this.showInvoiceDialog = false
  // }
}
